<template>
  <div v-if="patient && patient.medicalcard">
    <v-toolbar dark color="primary">
      <v-toolbar-title>{{ patient.fname }} {{ patient.lname }}</v-toolbar-title>
    </v-toolbar>

    <v-container>
      <v-row>
        <v-col>
          <v-row>
            <v-chip
              v-if="computedDateFormattedMomentjs"
              color="primary"
              outlined
              label
              class="mr-4"
            >
              <span class="mr-1 font-weight-bold">Birthdate: </span>
              {{ computedDateFormattedMomentjs }}
            </v-chip>

            <v-chip
              v-if="patient.medicalcard.age"
              color="primary"
              outlined
              label
              class="mr-4"
            >
              <span class="mr-1 font-weight-bold">Age:</span>
              {{ patient.medicalcard.age }}
            </v-chip>

            <v-chip
              v-if="patient.medicalcard.gender"
              color="primary"
              outlined
              label
              class="mr-4"
            >
              <span class="mr-1 font-weight-bold">Gender:</span>
              {{ patient.medicalcard.gender }}
            </v-chip>

            <v-chip
              v-if="patient.medicalcard.height"
              color="primary"
              outlined
              label
              class="mr-4"
            >
              <span class="mr-1 font-weight-bold">Height:</span>
              {{ patient.medicalcard.height }} inches
            </v-chip>

            <v-chip
              v-if="patient.medicalcard.weight"
              color="primary"
              outlined
              label
              class="mr-4"
            >
              <span class="mr-1 font-weight-bold">Weight:</span>
              {{ patient.medicalcard.weight }} pounds
            </v-chip>
            <v-spacer></v-spacer>
          </v-row>
        </v-col>
      </v-row>

      <template>
        <v-tabs
          id="settingsTabs"
          background-color="transparent"
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
          center-active
        >
          <v-tab ripple>Exam</v-tab>
          <v-tab ripple>Notes</v-tab>
          <v-tab-item>
            <Exam
              :patientId="patient.patientId"
              :uniqueName="patient.id"
              :practiceId="patient.practiceId"
              class="mt-4"
            />
          </v-tab-item>
          <v-tab-item>
            <v-card rounded="lg">
              <CallNotes
                :patientId="patient.patientId"
                :uniqueName="patient.id"
                :practiceId="patient.practiceId"
                class="mt-4"
              />
            </v-card>
            <PastNotes
              :patientId="patient.patientId"
              :uniqueName="patient.id"
              :practiceId="patient.practiceId"
              class="mt-4"
            />
          </v-tab-item>

          <v-spacer></v-spacer>
          <div v-if="patient.lastUpdated && patient.isExamComplete">
            <v-chip color="green" class="white--text" label
              >Updated: {{ patient.lastUpdated }}</v-chip
            >
          </div>
          <div v-else>
            <v-chip color="red" class="white--text" label
              >Exam Not Completed</v-chip
            >
          </div>
        </v-tabs>
      </template>
    </v-container>
  </div>
</template>

<script>
import * as fb from '@/firebase'
import * as moment from 'moment'
import ProxyLayout from '@/layouts/ProxyLayout'
import CallNotes from '@/components/room/CallNotes'
import PastNotes from '@/components/room/PastNotes'
import Exam from '@/components/room/Exam'
export default {
  name: 'PastAppointment',
  props: ['user'],
  components: {
    Exam,
    CallNotes,
    PastNotes
  },
  data() {
    return {
      uniqueName: '',
      patientId: null,
      practiceId: '',
      appointment: {},
      patient: {},
      currentUser: null
    }
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user) {
          this.currentUser = this.user
        }
      }
    },
    appointment: {
      immediate: true,
      async handler() {
        this.patientId = null
        if (this.appointment.patientId) {
          let vitalStamp = null
          if (this.appointment.vitalStamp) {
            vitalStamp = moment(this.appointment.vitalStamp.toDate()).format(
              'MM/DD/YYYY HH:mm A'
            )
          }
          try {
            await fb.patientsCollection
              .doc(this.appointment.patientId)
              .get()
              .then(snap => {
                const data = snap.data()
                this.patient = {
                  patientId: this.appointment.patientId,
                  uniqueName: this.appointment.uniqueRoom,
                  practiceId: this.practiceId,
                  id: this.appointment.id,
                  lastUpdated: vitalStamp,
                  isExamComplete: this.appointment.isExamComplete,
                  ...data
                }
              })
          } catch (error) {
            console.log(error)
          }
        }
      }
    }
  },
  async created() {
    this.$emit(`update:layout`, ProxyLayout)
    this.uniqueName = this.$route.query.un
    this.practiceId = this.$route.query.pr
    this.patientId = this.$route.query.pi

    await this.$bind(
      'appointment',
      fb.db.doc(`Practices/${this.practiceId}/Appointments/${this.uniqueName}`)
    )
  },
  computed: {
    computedDateFormattedMomentjs: {
      get() {
        return this.patient.medicalcard.birthdate
          ? moment(this.patient.medicalcard.birthdate.toDate()).format(
              'MMMM DD, YYYY'
            )
          : ''
      },
      set() {}
    }
  }
}
</script>
